import { useNavigate } from "react-router-dom";
import './navbar.scss'
import { t } from "i18next";
import { LanguageSelector } from "components/languageSelector/languageSelector";

export const NavBar = () => {
    const navigate = useNavigate()
    return (<header className="nav-bar bg-primary d-flex" >
        <button id="home" className='btn btn-outline-transparent'><img src='img/TitleAndLogo.svg' alt="logo" onClick={() => navigate('/')} /></button>
        <button id="products" className='btn btn-lg btn-outline-transparent' onClick={() => navigate('/products')}> {t('products')} </button>
        <button id="rules" className='btn btn-lg btn-outline-transparent' onClick={() => navigate('/rules')}> {t('rules')} </button>
        <div className="ml-auto"> <LanguageSelector /></div>
        {/* <button id="products" className='btn btn-outline-transparent' onClick={() => navigate('/products')}>  <i className="bi bi-basket"></i> </button> */}
    </header >)
}



