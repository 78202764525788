import { ProductDialog } from "components/productDialog/productDialog";
import { ProductInfoListItem } from "components/productTile/productInfoListItem.interface";
import { ProductTile } from "components/productTile/productTile";
import { t } from "i18next";
import { useEffect, useState } from "react";

export const ProductsPage = () => {
    const [selectedProductTileId, setSelectedProductTileId] = useState<string>();
    const [selectedProductTile, setSelectedProductTile] =
        useState<ProductInfoListItem>();

    // todo: fetch from somewhere else
    const [tileData] = useState<ProductInfoListItem[]>([
        {
            id: "208485d3-182c-49c4-bfb2-d075cbb5b29f",
            imgUrl: "img/Olsen 4.jpg",
            productImgs: [
                { imgUrl: "img/Olsen 1.jpg", imgId: "imageId1" },
                { imgUrl: "img/Olsen 2.jpg", imgId: "imageId2" },
                { imgUrl: "img/Olsen 3.jpg", imgId: "imageId3" },
                { imgUrl: "img/Olsen 4.jpg", imgId: "imageId4" },
                { imgUrl: "img/Olsen 5.jpg", imgId: "imageId5" },
            ],
            title: t("olsen"),
            price: 200,
            sortPosition: 0,
        },
        {
            id: "791d4876-12ed-413e-8151-a4316429d8f1",
            imgUrl: "img/Ludo_2022_003_1.jpg",
            productImgs: [
                { imgUrl: "img/Ludo_2022_003_1.jpg", imgId: "imageId1" },
                { imgUrl: "img/Ludo_2022_003.jpg", imgId: "imageId2" },
                { imgUrl: "img/Ludo_2022_006.jpg", imgId: "imageId3" },
                { imgUrl: "img/Ludo_2022_010.jpg", imgId: "imageId4" },
                { imgUrl: "img/Ludo_2022_012.jpg", imgId: "imageId5" },
            ],
            title: t("ludo"),
            price: 299.95,
            sortPosition: 1,
        },
        {
            id: "b24925e5-c72d-47ef-8bb9-4092cd0828bd",
            imgUrl: "img/bøur.jpg",
            productImgs: [],
            title: t("field"),
            price: 199.95,
            sortPosition: 2,
        },
        {
            id: "38da8d69-b125-4c45-9b68-4701898889d4",
            imgUrl: "img/stari.jpg",
            productImgs: [],
            title: t("sparrow"),
            price: 199.95,
            sortPosition: 3,
        },
        {
            id: "c3333681-ca6f-4b95-a076-f64833b32e59",
            imgUrl: "img/Súla.jpg",
            productImgs: [],
            title: t("sula"),
            price: 199.95,
            sortPosition: 4,
        },
        {
            id: "b77bf7bf-2e29-4452-90d8-3ee6599b24c9",
            imgUrl: "img/Toftavatn.jpg",
            title: t("toftavatn"),
            price: 299.95,
            sortPosition: 5,
        },
        {
            id: "f03b5672-7e69-47d2-8e90-1a7fd565d604",
            imgUrl: "img/Ær.jpg",
            productImgs: [],
            title: t("aer"),
            price: 299.95,
            sortPosition: 6,
        },
    ]);

    const onClose = () => {
        setSelectedProductTileId(undefined);
    };

    useEffect(() => {
        setSelectedProductTile(
            tileData?.find((_) => _.id === selectedProductTileId)
        );
    }, [tileData, selectedProductTileId]);

    return (
        <div
            className="d-flex justify-content-center mt-5"
            style={{ paddingLeft: "10vw", paddingRight: "10vw" }}
        >
            <div className="d-flex flex-row justify-content-center flex-wrap ">
                {tileData
                    .sort((a, b) => a.sortPosition - b.sortPosition)
                    .map((tile, index) => {
                        return (
                            <ProductTile
                                key={index}
                                {...tile}
                                setSelectedProductTile={setSelectedProductTileId}
                            />
                        );
                    })}
            </div>
            <ProductDialog
                selectedProductTile={selectedProductTile}
                onClose={onClose}
            />
        </div>
    );
};
