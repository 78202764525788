import i18next, { InitOptions } from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector"

i18next
  .use(ChainedBackend)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    supportedLngs:['en','da-DK','fo'],
    //debug:true,
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend((lng:string, ns:string) => import(`./locales/${lng}.json`))
      ], 
      backendOptions: [{
        loadPath: '/locales/{{lng}}.json'
      }],
    },
    detection: { // Options for language detection 
      order: ['localStorage', 'cookie', 'navigator'], 
      caches: ['localStorage', 'cookie'] 
      }
  } as InitOptions);

  export default i18next;