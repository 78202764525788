import { Link } from 'react-router-dom'
import './footer.scss'
import { t } from 'i18next'

export const Footer = () => (
    <div className="footer-style" >
        <div className='footer-wrapper flex-wrap'>
            <div className='flex-column flex-grow-1 ms-4'>
                <div className='flex-row h4'> {t('contact')} </div>
                <div className='flex-row'>  </div>
                <div className='flex-row'> Ívf. Spideog </div>
                <div className='flex-row'> Peturstrøð 10 </div>
                <div className='flex-row'> 100 Tórshavn </div>
                <div className='flex-row'> {t('faroe-Islands')} </div>
                <div className='flex-row'> {t('v-number')}: 655678 </div>
                <div className='flex-row'> <a href='mailto:hoo@spideogames.com' className='text-dark'>hoo@spideogames.com</a> </div>
            </div>
            <div className='flex-column flex-grow-1'>
                <div className='flex-row h4'> {t('customerSupport')} </div>
                <div className='flex-row'>  </div>
                <div className='flex-row'> {t("terms-and-conditions")} </div>
                <div className='flex-row'>  </div>
                <div className='flex-row'> {t('about-us')} </div>
            </div>
            <div className='flex-column flex-grow-1'>
                <div className='flex-row h4'> {t('administration')} </div>
                <div className='flex-row'> </div>
                <div className='flex-row'> {t('log-in')} </div>
            </div>
        </div>
    </div>
)