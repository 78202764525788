import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './productDialog.scss'
import { ProductDialogProps } from "./productDialog.interface";
import { ImageCarousel } from "components/ImageCarousel/ImageCarousel";
import { ProductImage } from "components/productTile/productInfo.interface";

export const ProductDialog = ({ selectedProductTile, onClose }: ProductDialogProps) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        selectedProductTile ? setShow(true) : setShow(false)
    }, [selectedProductTile])

    const [selectedImage, setSelectedImage] = useState<ProductImage | undefined>(selectedProductTile?.productImgs?.[0])

    return (
        <Modal show={show} onHide={onClose} dialogClassName="product-dialog-style" size="lg">
            <Modal.Header className="bg-primary">
                <Modal.Title> {selectedProductTile?.title} </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column h-100" style={{ overflow: 'scroll' }}>
                <div className="d-flex flex-row h-100">
                    <div className="d-flex flex-column mt-auto h-100 mr-4" style={{ overflowY: 'scroll', width: '12rem' }}>
                        <ImageCarousel imgs={selectedProductTile?.productImgs} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
                    </div>
                    <div className="d-flex flex-row">
                        <img hidden={!selectedImage} src={selectedImage?.imgUrl} className="selected-image-style" alt={`product ${selectedImage?.imgId}`} />
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer className="bg-primary">
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
