import { useEffect, useState } from "react";
import { Lang } from "../../enums/lang";
import i18next from "i18next";
import "./languageSelector.scss";

export const LanguageSelector = () => {
    const selectedClass = 'border border-1 border-secondary shadow rounded-circle'
    const [selectedLanguage, setSelectedLanguage] = useState<Lang>(i18next.language as Lang)

    useEffect(() => {
        setSelectedLanguage(i18next.language as Lang)
    }, [i18next.language])

    const onLanguageSelected = (language: Lang) => {
        i18next.changeLanguage(language, () => {
            setSelectedLanguage(language)
            window.location.reload() // could be if handled in a HOC useContext
        })
    }

    return (
        <div className="lang-selector">
            <button className={`btn btn-outline-transparent border-0`}>
                <img
                    src="img/FlagFo.png"
                    alt="faroese flag"
                    className={`mx-auto ${selectedLanguage === Lang.Fo ? selectedClass.toLocaleLowerCase() : ''}`}
                    onClick={() => onLanguageSelected(Lang.Fo)}
                />
            </button>
            <button className={`btn btn-outline-transparent  border-0`}>
                <img
                    src="img/FlagEn.png"
                    alt="english flag"
                    className={`mx-auto ${selectedLanguage === Lang.En ? selectedClass.toLocaleLowerCase() : ''}`}
                    onClick={() => onLanguageSelected(Lang.En)}
                />
            </button>
            <button className={`btn btn-outline-transparent  border-0`}>
                <img
                    src="img/FlagDk.png"
                    alt="danish flag"
                    className={`mx-auto ${selectedLanguage === Lang.Dk ? selectedClass.toLocaleLowerCase() : ''}`}
                    onClick={() => onLanguageSelected(Lang.Dk)}
                />
            </button>
        </div>
    );
};
