import { Route, Routes } from 'react-router-dom'
import { RulesPage } from './pages/rules/rulesPage'
import { Home } from './pages/home/homePage'
import { App } from './App'
import { ProductsPage } from 'pages/products/productsPage'
import { withTranslation } from 'react-i18next'

const AppRouter = () => {
    return (

        <Routes>
            <Route element={<App />}>
                <Route index element={<Home />} />
                <Route path="/rules" element={<RulesPage />} />
                <Route path="/products" element={<ProductsPage />} />
            </Route>
        </Routes>
    )
}

export default withTranslation()(AppRouter);