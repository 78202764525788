import "./App.scss"
import './styling/bootstrap.min.css';

import "./i18n";
import i18next from 'i18next'
import { useState } from 'react'
import { Outlet } from 'react-router-dom';
import { NavBar } from "./components/navbar/navbar";
import { Footer } from "components/footer/footer";
import { CenterSpinner } from "components/centerSpinner/centerSpinner";

export const App = () => {
  const [areTranslationsLoaded, setAreTranslationsLoaded] = useState(false)
  i18next.on('loaded', (_) => setAreTranslationsLoaded(true));

  return (
    <div className=" app-wrapper">
      <NavBar />
      {areTranslationsLoaded ? <Outlet /> : <CenterSpinner />}
      <Footer />
    </div>
  );
}




