import './productTile.scss'
import { ProductTileProps } from './ProductTileProps.interface'

export const ProductTile = ({ id, imgUrl, title, price, setSelectedProductTile }: ProductTileProps) => {
    return (
        <div className="product-tile" key={title} onClick={() => setSelectedProductTile(id)}>
            <img src={imgUrl} height={300} width={300} alt={title} />
            <h5 > {title}</h5>
            <p className="text-center text-secondary"> kr.{price} </p>
        </div>
    )
}

